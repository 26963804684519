import { useEffect, useRef, useState } from "react";
import { isEmpty, kebabCase } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { Button } from "@hydra/atom/components";
import toast from "react-hot-toast";
import { addDays } from "date-fns";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer, CustomActionDropdown } from "@/components/dynamic";
import { useModal } from "@/hooks";
import { AlertModal } from "@/components/modals";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getTaxRules } from "@/api/finance/taxRuleApi";
import { calculateTax, formatDecimalValues } from "@/utils/helpers";
import {
  getAMCInvoiceFormTableColumns,
  getAMCInvoicePaymentTableColumns,
  getExpensePaymentTableColumns
} from "@/components/finance/account-receivables/tableWithCheckboxData";
import showToast from "@/utils/toast/helpers";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";

const formatBlanketAgreement = (blanketAgreements) =>
  blanketAgreements.map((item) => ({
    ...item,
    key: item.number,
    isSelected: false,
  }));

const filteredActions = () => {
  const actions = [
    {
      title: "Delete",
      onClick: () => toggleAlertDialogue(row.original.key),
      icon: "trash-icon"
    },
    {
      title: "Edit",
      onClick: () => { editJob(row.original.key); },
      icon: "edit-icon"
    },
  ];
  return actions.filter((item) => checkPermission("Model", dynamicObjectMap.get("WorkOrderJobObjectName"), item.title));
};

function HeaderRightContent({
  openModal, isPaymentSelected, setIsPaymentSelected, showButton, ...rest
}) {
  const onPaymentClick = () => {
    setIsPaymentSelected(true);
    openModal();
  };

  const onAMCClick = () => {
    setIsPaymentSelected(false);
    openModal();
  };
  const actions = [
    {
      title: "Select AMC for Payments",
      onClick: () => { onAMCClick(); },
      icon: "document-icon"
    },
    {
      title: "Select Advance Payments",
      onClick: () => { onPaymentClick(); },
      icon: "bag-icon"
    }
  ];
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton ? (
        <div className="action-cell">
          <CustomActionDropdown
            actions={actions}
            trigger={(
              <div className="action-dropdown-trigger">
                <span className="text">Actions</span>
                <span className="material-icons-outlined">expand_more</span>
              </div>
        )}
            testId="Add-GL-Action-Button"
          />
        </div>
      ) : null}
    </defaultComponents.HeaderRightContent>
  );
}

function AMCInvoiceForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [toastId, setToastId] = useState();
  const [isBlanketSelected, setIsBlanketSelected] = useState(false);
  const [isPaymentSelected, setIsPaymentSelected] = useState(false);
  const [blanketAgreementTableData, setBlanketAgreementTableData] = useState([]);
  const [blanketPaymentDetailsTableData, setBlanketPaymentDetailsTableData] = useState([]);
  const [paymentDetailsTableData, setPaymentDetailsTableData] = useState([]);

  const { isOpen, closeModal, openModal } = useModal(false);
  const { data: taxRulesData } = useQuery(["tax-rule"], getTaxRules);

  const { data: blanketAgreementData, isLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("BlanketAgreementObjectName")),
      "Active",
      state?.supplier?.value,
    ],
    () =>
      getDynamicObjectRecords(
        dynamicObjectMap.get("BlanketAgreementObjectName"),
        {
          sortBy: "CreatedAt",
          sortType: "DESC",
          agreementMethod: "AMC",
          "status[in]": "Active,Ended,Expired",
          supplier: state?.supplier?.value,
          queryMode: "Deep",
        },
        {
          enabled: Boolean(state?.supplier?.value),
        }
      )
  );

  const { data: paymentData, isLoading: paymentIsLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("PaymentObjectName")),
      "Active",
      state?.supplier?.value,
    ],
    () =>
      getDynamicObjectRecords(
        dynamicObjectMap.get("PaymentObjectName"),
        {
          paymentType: "SupplierAdvance",
          payee: state?.supplier?.value,
          "status[in]": "PartiallyAdjusted,Posted",
          queryMode: "Deep",
        },
        {
          enabled: Boolean(state?.supplier?.value),
        }
      )
  );

  const formatPayment = (payments) =>
    payments.map((item) => ({
      ...item,
      advanceAmount: Number(item?.total) - Number(item?.adjustedAmount ?? 0),
      adjustmentAmount: Number(item?.total) - Number(item?.adjustedAmount ?? 0),
      key: item.number,
      isSelected: false,
    }));

  useEffect(() => {
    if (paymentData || paymentIsLoading) {
      if (!state?.supplier?.value) return;
      let toastIdValue;
      if (paymentIsLoading) {
        toastIdValue = toast.loading("Loading Supplier Advance...")
        setToastId(toastIdValue);
      }
      if (!paymentData) return;
      const paymentDetailData = formatPayment(paymentData.data);

      if (!paymentDetailData.length) {
        toast.dismiss(toastIdValue);
        showToast("No Supplier Advance found for the given supplier!", "info");
      } else {
        toast.dismiss(toastIdValue);
        showToast("Supplier Advance loaded!", "success");
      }
      ref.current.setFormValue("tRN", state?.supplier?.tRN);
      setPaymentDetailsTableData(paymentDetailData);
      openModal();
    }

    return () => {
      if (toastId) {
        toast.dismiss(toastId);
      }
    };
  }, [paymentData, state?.supplier?.value, isLoading]);

  useEffect(() => {
    if (blanketAgreementData || isLoading) {
      if (!state?.supplier?.value) return;
      if (isLoading) {
        setToastId(toast.loading("Loading AMCs..."));
      }
      if (!blanketAgreementData) return;
      const blanketAgreementDetailData = formatBlanketAgreement(blanketAgreementData.data);

      if (!blanketAgreementDetailData.length) {
        toast.dismiss(toastId);
        showToast("No AMC found for the given supplier!", "info");
      } else {
        toast.dismiss(toastId);
        showToast("AMCs loaded!", "success");
      }
      ref.current.setFormValue("tRN", state?.supplier?.tRN);
      setBlanketAgreementTableData(blanketAgreementDetailData);
      openModal();
    }

    return () => {
      if (toastId) {
        toast.dismiss(toastId);
      }
    };
  }, [blanketAgreementData, state?.supplier?.value, isLoading]);

  const setAdvanceAmount = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;

    const {
      advanceAmount, supplierBalance, subtotal, total
    } = formState;

    let openBalance = total;

    if (!supplierBalance || !subtotal) {
      ref.current.setFormState({
        advanceAmount: 0,
        openBalance,
      });
      return;
    }

    const isAmountMoreThanTotal = advanceAmount > subtotal;
    const isAmountMoreThanBalance = advanceAmount > supplierBalance;

    if (isAmountMoreThanBalance) {
      openBalance = total - supplierBalance;
      ref.current.setFormState({
        advanceAmount: supplierBalance,
        openBalance,
      });
      return;
    }

    if (isAmountMoreThanTotal) {
      openBalance = total - subtotal;
      ref.current.setFormState({
        advanceAmount: subtotal,
        openBalance,
      });
      return;
    }

    openBalance = total - Number(advanceAmount);
    ref.current.setFormValue("openBalance", openBalance);
  };

  const setTaxAndTotal = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { lineItem } = formState;

    const subtotal = lineItem?.reduce(
      (prevValue, currentValue) => Number(prevValue) + Number(currentValue.amount),
      0
    );

    const taxAmount = lineItem?.reduce(
      (prevValue, currentValue) => Number(prevValue) + Number(currentValue.taxAmount),
      0
    );

    const total = lineItem?.reduce(
      (prevValue, currentValue) => Number(prevValue) + Number(currentValue.totalAmount),
      0
    );

    const data = {
      subtotal,
      taxAmount,
      total,
    };

    data.openBalance = data.total;
    ref.current.setFormState(data);
    setAdvanceAmount(key, value);
  };

  const setSupplierData = (value) => {
    setState((prev) => ({ ...prev, supplier: value }));
    if (!value) {
      ref.current.setFormState({
        tRN: "",
        supplierBalance: 0
      });
      return;
    }

    ref.current.setFormState({
      tRN: value?.tRN,
      supplierBalance: value?.openingBalance ?? 0
    });
  };

  const setAMCData = (value) => {
    if (!value) {
      ref.current.setFormValue("tax", null);
      return;
    }

    if (value?.tax && !isEmpty(value?.tax)) {
      ref.current.setFormValue("tax", {
        label: value.tax.name,
        value: value.tax.id,
      });
    }
  };

  const getTaxByBuildingType = (buildingType) => {
    let tax = null;

    switch (buildingType) {
      case "Residential":
        tax = taxRulesData?.data.find((i) => i.name === "Input VAT Non-recoverable - Residential (5%)");
        break;

      case "Commercial":
        tax = taxRulesData?.data.find((i) => i.name === "Input VAT Recoverable - Commercial (5%)");
        break;

      case "Residential/Commercial":
        taxRulesData?.data.find((i) => i.name === "Input VAT Partial-recoverable Admin (5%)");
        break;

      default:
        break;
    }

    if (tax) {
      tax.label = tax.name;
      tax.value = tax.id;
    }

    return tax;
  };

  const setDueDate = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { supplier } = formState;
    if (!supplier || !value) return;

    const { paymentTerm } = supplier;

    let dueDate = null;

    if (paymentTerm) {
      const { netDueInDay: paymentTermDueDays } = paymentTerm;

      if (paymentTermDueDays) {
        dueDate = addDays(new Date(value), paymentTermDueDays);
      }
    }

    ref.current.setFormState({
      dueDate,
    });
  };

  const onChildStateChange = ({
    index, key, value, parentField, parentFieldType
  }) => {
    const formState = ref.current.getState();
    const stateKey = `${parentField}${parentFieldType}`;
    let parentFieldState = formState[stateKey] ?? {};

    if (index > -1) {
      parentFieldState = formState[parentField][index];
    }

    if (parentField === "lineItem") {
      switch (key) {
        case "asset": {
          parentFieldState[key] = value;
          const { asset } = parentFieldState;

          if (asset && asset.lookupObjectName === "Building") {
            const { unitProperty } = asset;
            parentFieldState.tax = getTaxByBuildingType(unitProperty);
          }
          break;
        }
        case "quantity":
        case "rate":
        case "tax":
        case "amount": {
          parentFieldState[key] = value;
          const { tax, amount } = parentFieldState;
          parentFieldState.amount = amount;
          const { amountOfTax } = formState;

          if (tax && amountOfTax) {
            const parameters = {
              amount,
              amountOfTax,
              tax,
              taxAmount: "",
            };
            const { taxAmount, principalAmount } = calculateTax(parameters);
            parentFieldState.taxAmount = taxAmount;
            parentFieldState.totalAmount = formatDecimalValues(
              Number(principalAmount) + Number(taxAmount)
            );
          }
          break;
        }

        default:
          break;
      }
    }
  };

  const adjustAmountsAndSum = (arr) => {
    let totalAdjustmentAmount = 0;

    arr.forEach((item) => {
      if (item.adjustmentAmount > item.advanceAmount) {
        item.adjustmentAmount = item.advanceAmount;
      }
      totalAdjustmentAmount += Number(item.adjustmentAmount);
    });

    return {
      adjustedArray: arr,
      totalAdjustmentAmount
    };
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "supplier":
        setSupplierData(value);
        break;

      case "blanketAgreement":
        setAMCData(value);
        break;

      case "lineItem":
      case "lineItemTable":
      case "amountOfTax":
      case "tax":
        setTaxAndTotal(key, value);
        break;

      case "supplierInvoiceDate":
        setDueDate(key, value);
        break;

      case "advanceAmount": {
        setAdvanceAmount(key, value);
        break;
      }

      case "advancePayment": {
        const result = adjustAmountsAndSum(value);
        ref.current.setFormState({ advanceAmount: result.totalAdjustmentAmount });
        break;
      }

      default:
        break;
    }
  };

  const handleConfirm = () => {
    if (blanketAgreementTableData.length && !isBlanketSelected && !isPaymentSelected) {
      const selectedAgreement = blanketAgreementTableData.find((q) => q.isSelected);
      if (selectedAgreement) {
        const allPaymentDetails = selectedAgreement.paymentDetail
          .filter((p) => p.paymentStatus === "Draft")
          .map((payment) => ({
            // TODO: refactor
            ...selectedAgreement,
            ...payment,
            isSelected: true,
            id: selectedAgreement.id,
            key: payment.id,
            number: selectedAgreement.number,
            assetName: payment?.asset?.name,
            assetNumber: payment?.asset?.number,
            amountOfTax: selectedAgreement.amountOfTax,
            subtotal: payment.amount,
            total: payment.totalAmount,
          }));
        setBlanketPaymentDetailsTableData(allPaymentDetails);
        setIsBlanketSelected(true);
        return;
      }
    }

    if (blanketPaymentDetailsTableData.length && isBlanketSelected && !isPaymentSelected) {
      setIsBlanketSelected(false);
      const selectedPayments = blanketPaymentDetailsTableData.filter((q) => q.isSelected);
      if (selectedPayments.length) {
        ref.current.setFormState({
          lineItem: selectedPayments.map((item) => {
            const lineItemData = {
              ...item,
              paymentId: item.key,
              asset: {
                label: item.asset.name,
                value: item.asset.id,
                lookupObjectName: item.asset.objectName,
              },
            };

            if (item.tax && !isEmpty(item.tax)) {
              lineItemData.tax = {
                ...item.tax,
                label: item.tax.name,
                value: item.tax.id,
              };
            }

            if (item.account && !isEmpty(item.account)) {
              const { clearingAccount } = selectedPayments[0];
              if (clearingAccount) {
                lineItemData.account = {
                  label: clearingAccount.name,
                  value: clearingAccount.id,
                };
              }
            }

            return lineItemData;
          }),
          amountOfTax: {
            label: selectedPayments[0].amountOfTax,
            value: selectedPayments[0].amountOfTax,
          },
          amountBeforeTax: selectedPayments?.reduce(
            (total, currentValue) => total + Number(currentValue.amount || 0),
            0
          ),
          tax: {
            ...selectedPayments[0].tax,
            label: selectedPayments[0].tax.name,
            value: selectedPayments[0].tax.id,
          },
          blanketAgreement: {
            value: selectedPayments[0].id,
            label: selectedPayments[0].number,
          },
          subtotal: selectedPayments?.reduce(
            (total, currentValue) => total + Number(currentValue.subtotal || 0),
            0
          ),
          taxAmount: selectedPayments?.reduce(
            (total, currentValue) => total + Number(currentValue.taxAmount || 0),
            0
          ),
          openBalance: selectedPayments?.reduce(
            (total, currentValue) => total + Number(currentValue.total || 0),
            0
          ),
          total: selectedPayments?.reduce(
            (total, currentValue) => total + Number(currentValue.total || 0),
            0
          ),
        });
      }
    }

    if (paymentDetailsTableData.length && isPaymentSelected) {
      const selectedPayments = paymentDetailsTableData.filter((q) => q.isSelected);
      const result = adjustAmountsAndSum(selectedPayments);
      if (selectedPayments.length) {
        ref.current.setFormState({
          advancePayment: selectedPayments.map((item) => ({
            payment: {
              label: item.number,
              value: item.id
            },
            adjustmentAmount: item.total,
            advanceAmount: item.total
          })),
          advanceAmount: result.totalAdjustmentAmount
        },
        );
      }
    }
    closeModal();
  };

  const onBeforeSave = () => {
    const formState = ref.current.getState();
    if (formState.advanceAmount > (formState.total || 0)) {
      toast.error(
        "Total advance amount should be equal to or less than total amount.");
      return false;
    }
    return true;
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title={`${
          blanketPaymentDetailsTableData.length && isBlanketSelected ? "Select Payments" : "Select AMC"
        }`}
        subtitle={`${
          blanketPaymentDetailsTableData.length && isBlanketSelected ?
            "Selected agreement has following pending payments" :
            "Selected supplier has following active agreements"
        }`}
        onClose={() => {
          setIsBlanketSelected(false);
          closeModal();
        }}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        size="large"
      >
        {blanketAgreementTableData.length && !isBlanketSelected && !isPaymentSelected ? (
          <TableWithCheckbox
            data={blanketAgreementTableData}
            searchKey={["number"]}
            columns={getAMCInvoiceFormTableColumns()}
            setData={setBlanketAgreementTableData}
          />
        ) : null}
        {blanketPaymentDetailsTableData.length && isBlanketSelected && !isPaymentSelected ? (
          <TableWithCheckbox
            data={blanketPaymentDetailsTableData}
            searchKey={["assetName"]}
            columns={getAMCInvoicePaymentTableColumns()}
            setData={setBlanketPaymentDetailsTableData}
            allowMultiple
            selectAll
          />
        ) : null}
        {paymentDetailsTableData.length && isPaymentSelected ? (
          <TableWithCheckbox
            data={paymentDetailsTableData}
            searchKey={["number"]}
            columns={getExpensePaymentTableColumns()}
            setData={setPaymentDetailsTableData}
            allowMultiple
            selectAll
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        initialData={state}
        ref={ref}
        objectName={dynamicObjectMap.get("AmcInvoiceObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onChildStateChange={onChildStateChange}
        onBeforeSave={onBeforeSave}
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              isPaymentSelected,
              setIsPaymentSelected,
              showButton: Boolean(paymentDetailsTableData.length),
              ...props,
            }),
        }}
      />
    </BoxedContent>
  );
}

export default AMCInvoiceForm;
