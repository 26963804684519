import { useMemo, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Checkbox, Button } from "@hydra/atom/components";
import { startCase } from "lodash";

import { BoxedContent, Header, Accordion } from "@/components/common";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { closeAccountingPeriod, reopenAccountingPeriod } from "@/api/finance/accountingPeriodApi";
import StaticObjectDetails from "@/pages/finance/detail-views/StaticObjectDetails";
import showToast from "@/utils/toast/helpers";

const listItems = [
  {
    module: "AccountsReceivable",
    items: [
      {
        type: "api",
        title: "Record all invoices",
        loadingStatus: "Checking all invoices...",
        successStatus: "All invoices verified",
        pendingStatus: "Complete pending tasks for invoices...",
        errorStatus: "Some invoices need to be recorded",
        api: () =>
          getDynamicObjectRecords(dynamicObjectMap.get("TenantObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            takePage: 1,
            limitPage: 10,
          }),
        check: (response) => response.data.length === 0,
        tasks: [
          {
            label: "Check for pending invoices",
            value: true,
            disabled: true,
          },
          {
            label: "Issue all invoices",
            value: false,
            disabled: false,
          },
          {
            label: "Enter all payments",
            value: false,
            disabled: false,
          },
        ],
      },
      {
        type: "api",
        title: "Revenue Recognition",
        loadingStatus: "Verifying revenue recognition for all contracts...",
        successStatus: "Revenue recognition verified for all contracts",
        pendingStatus: "Complete pending revenue recognition tasks...",
        errorStatus: "Some contracts have pending revenue recognition",
        api: () =>
          getDynamicObjectRecords(dynamicObjectMap.get("ContractObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            takePage: 1,
            limitPage: 10,
          }),
        check: (response) => response.data.length === 0,
        tasks: [
          {
            label: "Check for revenue recognition",
            value: true,
            disabled: true,
          },
        ],
      },
      {
        type: "api",
        title: "PDC",
        loadingStatus: "Verifying all PDCs...",
        successStatus: "All PDCs are cleared for the current period",
        pendingStatus: "Complete pending PDC tasks...",
        errorStatus: "Some PDCs need to be cleared",
        api: () =>
          getDynamicObjectRecords(dynamicObjectMap.get("PDCObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            takePage: 1,
            limitPage: 10,
          }),
        check: (response) => response.data.length === 0,
        tasks: [
          {
            label: "Verify cleared PDCs",
            value: true,
            disabled: true,
          },
        ],
      },
      {
        type: "link",
        title: "Reconcile AR Subledger to GL",
        loadingStatus: "Generating customer aging report...",
        successStatus: "Customer aging report generated",
        pendingStatus: "Complete pending tasks for reconciliation...",
        errorStatus: "Could not generate customer aging report",
        check: () => true,
        tasks: [
          {
            label: "Verify customer aging report",
            value: false,
            disabled: true,
            link: {
              label: "View",
              to: "/finance/reports/tenant-aging",
            },
          },
          {
            label: "Match with GL",
            value: false,
            disabled: false,
          },
          {
            label: "Resolve discrepancies",
            value: false,
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    module: "AccountsPayable",
    items: [
      {
        type: "api",
        title: "Record all invoices",
        loadingStatus: "Checking all invoices...",
        successStatus: "All invoices verified",
        pendingStatus: "Complete pending tasks for invoices...",
        errorStatus: "Some invoices need to be recorded",
        api: () =>
          getDynamicObjectRecords(dynamicObjectMap.get("TenantObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            takePage: 1,
            limitPage: 10,
          }),
        check: (response) => response.data.length === 0,
        tasks: [
          {
            label: "Check for pending invoices",
            value: true,
            disabled: true,
          },
          {
            label: "Issue all invoices",
            value: false,
            disabled: false,
          },
          {
            label: "Enter all payments",
            value: false,
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    module: "FixedAsset",
    items: [
      {
        type: "api",
        title: "Record all invoices",
        loadingStatus: "Checking all invoices...",
        successStatus: "All invoices verified",
        pendingStatus: "Complete pending tasks for invoices...",
        errorStatus: "Some invoices need to be recorded",
        api: () =>
          getDynamicObjectRecords(dynamicObjectMap.get("TenantObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            takePage: 1,
            limitPage: 10,
          }),
        check: (response) => response.data.length === 0,
        tasks: [
          {
            label: "Check for pending invoices",
            value: true,
            disabled: true,
          },
          {
            label: "Issue all invoices",
            value: false,
            disabled: false,
          },
          {
            label: "Enter all payments",
            value: false,
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    module: "GeneralLedger",
    items: [
      {
        type: "api",
        title: "Record all invoices",
        loadingStatus: "Checking all invoices...",
        successStatus: "All invoices verified",
        pendingStatus: "Complete pending tasks for invoices...",
        errorStatus: "Some invoices need to be recorded",
        api: () =>
          getDynamicObjectRecords(dynamicObjectMap.get("TenantObjectName"), {
            sortBy: "CreatedAt",
            sortType: "DESC",
            takePage: 1,
            limitPage: 10,
          }),
        check: (response) => response.data.length === 0,
        tasks: [
          {
            label: "Check for pending invoices",
            value: true,
            disabled: true,
          },
          {
            label: "Issue all invoices",
            value: false,
            disabled: false,
          },
          {
            label: "Enter all payments",
            value: false,
            disabled: false,
          },
        ],
      },
    ],
  },
];

const prepareInitialState = () => {
  const initialState = {};

  listItems.forEach((listItem) => {
    listItem.items.forEach((item, itemIndex) => {
      item.tasks.forEach((task, taskIndex) => {
        const checkboxKey = `${listItem.module}${itemIndex}${taskIndex}`;
        initialState[checkboxKey] = task.value;
      });
    });
  });

  return initialState;
};

const renderIcon = (status) => {
  switch (status) {
    case "loading":
      return <i className="fas fa-circle-notch fa-spin" />;

    case "success":
      return <i className="fas fa-check-circle" />;

    case "error":
      return <i className="fas fa-exclamation-circle" />;

    case "pending":
      return <i className="fas fa-circle" />;
    default:
      break;
  }
};

const renderStatus = (status, item) => {
  switch (status) {
    case "loading":
      return <p>{item.loadingStatus}</p>;

    case "pending":
      return <p>{item.pendingStatus}</p>;

    case "success":
      return <p>{item.successStatus}</p>;

    case "error":
      return <p>{item.errorStatus}</p>;

    default:
      break;
  }
};

const getItemCheckboxKeys = (module, itemIndex, item) => {
  const checkboxKeys = [];
  item.tasks.forEach((task, taskIndex) => {
    const checkboxKey = `${module}${itemIndex}${taskIndex}`;
    checkboxKeys.push(checkboxKey);
  });
  return checkboxKeys;
};

const getStatus = (isLoading, module, itemIndex, item, state, data) => {
  if (isLoading) {
    return "loading";
  }

  if (!item.check(data)) {
    return "error";
  }

  const checkboxKeys = getItemCheckboxKeys(module, itemIndex, item);
  let isPending = false;
  if (checkboxKeys.length) {
    for (let i = 0; i < checkboxKeys.length; i += 1) {
      const checkboxKey = checkboxKeys[i];
      const value = state[checkboxKey];
      if (!value) {
        isPending = true;
        break;
      }
    }
  }

  if (isPending) {
    return "pending";
  }

  return "success";
};

function CheckOffCard({
  index, item, module, handleCheckboxChange, state
}) {
  const {
    data,
    isInitialLoading: isLoading,
    refetch,
    isRefetching,
  } = useQuery([item.title], () => item.api(), {
    enabled: item.type === "api",
  });

  const isDataLoading = isLoading || isRefetching;

  const status = getStatus(isDataLoading, module, index, item, state, data);

  const handleRefresh = () => {
    refetch();
  };

  return (
    <div className="check-off-card">
      <div className="check-off-card-item-container">
        <h6>{item.title}</h6>
        {renderIcon(status)}
      </div>
      <div className="check-off-card-status-container">{renderStatus(status, item)}</div>
      {item.tasks.length && status !== "loading" ? (
        <div className="check-off-card-list-container">
          {item.tasks.map((checkboxItem, i) => {
            const checkboxKey = `${module}${index}${i}`;

            return (
              <div key={`Checkbox-List-Item-${i}`} className="check-off-card-list-item-container">
                <Checkbox
                  label={checkboxItem.label}
                  value={state[checkboxKey]}
                  onChange={(value) => handleCheckboxChange(checkboxKey, value)}
                />
                {checkboxItem.link && (
                  <Link to={checkboxItem.to} target="_blank">
                    View
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      ) : null}
      {status === "error" && (
        <button className="btn-refresh" type="button" aria-label="Refresh" onClick={handleRefresh}>
          <i className="fa fa-refresh" />
        </button>
      )}
    </div>
  );
}

const filterListItems = (moduleName) => {
  if (!moduleName) {
    return listItems;
  }

  const moduleListItems = listItems.filter((i) => i.module === moduleName);

  if (moduleListItems.length) {
    return moduleListItems;
  }

  return [];
};

function CloseAccountingPeriod() {
  const { id, interimPeriodId } = useParams();
  const [searchParams] = useSearchParams();
  const moduleName = searchParams.get("moduleName");
  const [state, setState] = useState(prepareInitialState());

  const shownListItems = useMemo(() => filterListItems(moduleName), [moduleName]);

  const closeMutation = useMutation(
    ({ number, financeModule }) => closeAccountingPeriod(id, number, financeModule),
    {
      onError: () => {
        showToast("Could not close interim accounting period. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Closed successfully", "success");
      },
    }
  );

  const reopenMutation = useMutation(
    ({ number, financeModule }) => reopenAccountingPeriod(id, number, financeModule),
    {
      onError: () => {
        showToast("Could not reopen interim accounting period. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Reopened successfully", "success");
      },
    }
  );

  const handleAction = () => {
    if (state.isClosed) {
      reopenMutation.mutate({ number: interimPeriodId, financeModule: moduleName });
    } else {
      closeMutation.mutate({ number: interimPeriodId, financeModule: moduleName });
    }
  };

  const handleCheckboxChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <BoxedContent className="dynamic-object-overview">
      <Header
        showBreadcrumb
        leftContent={(
          <div>
            <h1>Close Interim Accounting Period</h1>
          </div>
        )}
        rightContent={(
          <Button
            small
            onClick={handleAction}
            loading={closeMutation.isLoading || reopenMutation.isLoading}
          >
            {`${state.isClosed ? "Reopen" : "Close"} Interim Accounting Period`}
          </Button>
        )}
      />
      <StaticObjectDetails id={id} showHeader={false} />
      {shownListItems.map((listItem, i) => (
        <Accordion
          key={`Accordion-${i}`}
          title={startCase(listItem.module)}
          count={listItem.items.length}
        >
          <div className="row gy-4">
            {listItem.items.map((item, k) => (
              <div key={`Check-Off-Card-${k}`} className="col-md-3">
                <CheckOffCard
                  index={k}
                  module={listItem.module}
                  item={item}
                  handleCheckboxChange={handleCheckboxChange}
                  state={state}
                />
              </div>
            ))}
          </div>
        </Accordion>
      ))}
    </BoxedContent>
  );
}

CloseAccountingPeriod.propTypes = {};

export default CloseAccountingPeriod;
