import {
  getAgingRows, getSupplierStatementRows, getAdvancePaymentRows,
  getSupplierStatementColumns,
  getAgingColumns, getAdvancePaymentColumns
} from "./supplier-statement";
import DynamicObjectDetails from "@/pages/dynamic/detail-views/DynamicObjectDetails";
import { getSupplierOpenTransaction, getSupplierAging, getAdvancePayment } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";
import {
  BoxedContent, Header, HeaderLeftContent
} from "@/components/common";

export default function ReportSupplierStatement({ id, activeCompany }) {
  const supplierStatementFilters = {
    SupplierId: id
  };

  return (
    <>
      <DynamicObjectDetails objectName="Supplier" id={id} showHeader={false} showTransfers={false} />
      <BoxedContent>
        <Header
          leftContent={<HeaderLeftContent title="Advance Payments" subtitle="" icon="agent-icon-2" />}
        />
        <DynamicReport
          reportName="advance-payment"
          activeCompany={activeCompany}
          columnsDataProvider={getAdvancePaymentColumns}
          rowsDataProvider={getAdvancePaymentRows}
          fetchData={getAdvancePayment}
          filtersInitialState={supplierStatementFilters}
          orderBy=""
          showDateRangeFilter={false}
          isPaginated={false}
        />
      </BoxedContent>
      <BoxedContent>
        <Header
          leftContent={<HeaderLeftContent title="Open Transaction" subtitle="" icon="agent-icon-2" />}
        />
        <DynamicReport
          reportName="open-transaction"
          activeCompany={activeCompany}
          columnsDataProvider={getSupplierStatementColumns}
          rowsDataProvider={getSupplierStatementRows}
          fetchData={getSupplierOpenTransaction}
          filtersInitialState={supplierStatementFilters}
          orderBy=""
          showDateRangeFilter={false}
          isPaginated={false}
        />
      </BoxedContent>
      <BoxedContent>
        <Header
          leftContent={<HeaderLeftContent title="Aging" subtitle="" icon="agent-icon-2" />}
        />
        <DynamicReport
          reportName="aging"
          activeCompany={activeCompany}
          columnsDataProvider={getAgingColumns}
          rowsDataProvider={getAgingRows}
          fetchData={getSupplierAging}
          filtersInitialState={supplierStatementFilters}
          orderBy=""
          showDateRangeFilter={false}
          isPaginated={false}
        />
      </BoxedContent>
    </>
  );
}
