import { groupBy, range } from "lodash";
import {
  nonEditable,
  textCell,
  headerCell,
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";
import { formatCurrency } from "@/utils/helpers";

function getHeaderRow(columns) {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: columns.map((column) => headerCell(column.label)),
  };
}

function getGroupRows(title, groups, totalDebit, totalCredit, totalBalance,
  totalOpeningBalance, columns, groupIndex) {

  const groupTitle = groups[0]?.accountName;
  const groupCode = groups[0]?.accountCode;

  if (groups?.length) {
    return [
      {
        rowId: `${groupTitle}-${groupIndex}-Header`,
        height: ROW_HEIGHT,
        cells: [
          headerCell(`${groupTitle} (${groupCode})`, "light-green-bg font-bold", columns.length),
        ],
      },
      ...groups.map((item, index) => ({
        rowId: item.transferId,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }

          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      })),
      {
        rowId: `${title}-${groupIndex}-Footer`,
        height: ROW_HEIGHT,
        cells: [
          headerCell(title, "total-row"),
          ...range(0, columns.length - 5).map(() => headerCell("", "total-row")),
          headerCell(formatCurrency(totalDebit), "total-row-debit"),
          headerCell(formatCurrency(totalCredit), "total-row-credit"),
          headerCell(formatCurrency(totalOpeningBalance), "total-row-credit"),
          headerCell(formatCurrency(totalBalance), "total-row-credit"),
        ],
      },
    ];
  }
  return [];
}

function getGrandTotalRow(title,
  totalDebit, totalCredit, totalBalance, grandTotalOpeningBalance, columns) {

  return {
    rowId: `${title}Footer`,
    height: ROW_HEIGHT,
    cells: [
      headerCell(title, "light-green-bg font-bold"),
      ...range(0, columns.length - 5).map(() => headerCell("", "light-green-bg")),
      headerCell(formatCurrency(totalDebit), "light-green-bg font-bold"),
      headerCell(formatCurrency(totalCredit), "light-green-bg font-bold"),
      headerCell(formatCurrency(grandTotalOpeningBalance), "light-green-bg font-bold"),
      headerCell(formatCurrency(totalBalance), "light-green-bg font-bold"),
    ],
  };
}

// eslint-disable-next-line import/prefer-default-export
export function getJournalLedgerRows({ data, columns }) {
  let grandTotalCredit = 0;
  let grandTotalDebit = 0;
  let grandTotalBalance = 0;
  let grandTotalOpeningBalance = 0;

  if (!data) {
    return [];
  }

  const groups = groupBy(data?.data, "accountName");
  const groupValues = Object.values(groups);
  // Create group rows
  const groupedRows = groupValues.flatMap((group, index) => {
    const totalCredit = group.reduce(
      (accumulator, currentValue) => accumulator + +(currentValue?.creditAmount || 0),
      0
    );

    const totalDebit = group.reduce(
      (accumulator, currentValue) => accumulator + +(currentValue.debitAmount || 0),
      0
    );

    const { accountBalance } = group[0];
    const totalOpeningBalance = accountBalance ? (accountBalance) / 100 : 0;
    const totalBalance = totalOpeningBalance + totalDebit - totalCredit;
    grandTotalCredit += totalCredit;
    grandTotalDebit += totalDebit;
    grandTotalBalance += totalBalance;
    grandTotalOpeningBalance += totalOpeningBalance;
    return getGroupRows("Total", group, totalDebit, totalCredit, totalBalance, totalOpeningBalance, columns, index);
  });

  return [
    getHeaderRow(columns),
    ...groupedRows,
    getGrandTotalRow(
      "Grand Total",
      grandTotalDebit,
      grandTotalCredit,
      grandTotalBalance,
      grandTotalOpeningBalance,
      columns
    )
  ];
}
