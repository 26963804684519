import {
  useCallback, useState, useMemo
} from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Button, Input, Pagination } from "@hydra/atom/components";
import {
  BoxedContent,
  Header,
  SvgIcon,
  NoDataFound,
} from "@/components/common";

import {
  getJournalEntryColumns,
  getJournalEntryData
} from "@/components/finance/journal-entry/journalEntryTableData";
import showToast from "@/utils/toast/helpers";
import { skeletonLoadingRows } from "@/utils/helpers";
import { getJournalEntryLists, deleteJournalEntryDetailById } from "@/api/finance/journalEntryApi";
import { FinanceMasterTable } from "@/components/finance";
import { useDebounce } from "@/hooks";

const perPageOptions = [
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "36", value: 36 },
];

function JournalEntryList() {
  const [filterText, setFilterText] = useState("");
  const debouncedSearch = useDebounce(filterText, 300);
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    deleteJournalEntryDetailById,
    {
      onError: () => {
        showToast("Could not delete. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Deleted successfully", "success");
        queryClient.invalidateQueries({
          queryKey: ["journal-entry-list"]
        });
      },
    }
  );

  const navigate = useNavigate();
  const { isLoading, data } = useQuery(
    ["journal-entry-list", currentPage, perPage.value, debouncedSearch],
    () => getJournalEntryLists({
      pageNumber: currentPage,
      pageSize: perPage.value,
      type: "Adjusting Entry",
      "Number[like]": debouncedSearch
    })
  );

  const totalCount = useMemo(() => data?.totalCount || 0, [data?.data]);

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };

  const rightContent = useCallback(
    () => (
      <Button
        bordered
        onClick={() => navigate("new")}
        className="btn-with-icon"
        small
      >
        <Button.Prepend>
          <SvgIcon icon="plus-icon" />
        </Button.Prepend>
        New Journal Entry
      </Button>
    ),
    [navigate]
  );

  const renderTable = () => {
    if (!isLoading && data && !data?.data) {
      return (
        <NoDataFound
          title="No journal entry has been added"
          buttonText="Add new journal entry"
          onClick={() => navigate("new")}
        />
      );
    }

    return (
      <>
        <div className="d-flex">
          <Input
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by JE number"
          />
        </div>
        <FinanceMasterTable
          isLoading={isLoading}
          tableColumns={getJournalEntryColumns(data?.data || [])}
          data={getJournalEntryData(data?.data || skeletonLoadingRows(5))}
          objectName="journalEntry"
          handleDelete={(id) => {
            deleteMutation.mutate(id);
          }}
        />
        <Pagination
          className="dashboard-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          pageRangeDisplayed={3}
          pageNo={currentPage}
          handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={Math.ceil(totalCount / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
      </>
    );
  };

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<h1>Journal Entry</h1>}
        rightContent={rightContent()}
      />
      {renderTable()}
    </BoxedContent>
  );
}

export default JournalEntryList;
